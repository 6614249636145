<template>
  <div class="dashboard p-4">
    <h1 class="text-2xl text-gray-900 font-medium">Weight Loss Challenge</h1>
    <div class="mt-2 lg:flex block lg:gap-2">
      <div
        class="bg-white light:bg-gray-500 p-5 w-full rounded-md box-border shadow"
      >
        <br />
        <apexchart
          width="100%"
          type="area"
          :options="getOptions"
          :series="listSeries"
          :sparkline="{ enabled: true }"
        ></apexchart>
      </div>
      <div
        class="bg-white light:bg-gray-800 p-5 lg:w-96 lg:mt-0 mt-4 shadow rounded-md w-full"
      >
        <h2 class="light:text-gray-200 text-xl font-bold">Competitors</h2>
        <div class="mt-4 grid grid-cols-1 gap-4">
          <button class="w-full box-border flex gap-4">
            <span>
              <img
                src="../assets/img/eric.jpeg"
                alt=""
                width="50"
                height="50"
                class="rounded-full"
              />
            </span>
            <span class="text-left">
              <h1 class="font-bold text-xl light:text-gray-200">Eric</h1>
            </span>
          </button>
          <button class="w-full box-border flex gap-4">
            <span>
              <img
                src="../assets/img/hans.jpeg"
                alt=""
                width="50"
                height="50"
                class="rounded-full"
              />
            </span>
            <span class="text-left">
              <h1 class="font-bold text-xl light:text-gray-200">Hans</h1>
            </span>
          </button>
          <button class="w-full box-border flex gap-4">
            <span>
              <img
                src="../assets/img/heidi.jpeg"
                alt=""
                width="50"
                height="50"
                class="rounded-full"
              />
            </span>
            <span class="text-left">
              <h1 class="font-bold text-xl light:text-gray-200">Heidi</h1>
            </span>
          </button>
          <button class="w-full box-border flex gap-4">
            <span>
              <img
                src="../assets/img/kelly.jpeg"
                alt=""
                width="50"
                height="50"
                class="rounded-full"
              />
            </span>
            <span class="text-left">
              <h1 class="font-bold text-xl light:text-gray-200">Kelly</h1>
            </span>
          </button>
        </div>
      </div>
      <!--div
        class="bg-white dark:bg-gray-800 shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div class="mb-6">
          <label class="dark:text-gray-200 mb-2 text-sm" for="weight"
            >Name</label
          >
          <input id="name" v-model="name" type="text" class="rounded" />
        </div>
        <div class="mb-6">
          <label class="dark:text-gray-200 mb-2 text-sm" for="weight"
            >Weight</label
          >
          <input id="weight" v-model="weight" type="number" class="rounded" />
        </div>
        <div class="mb-4">
          <label for="date" class="dark:text-gray-200 mb-2 text-sm">Date</label>
          <input id="date" v-model="weightDate" type="date" class="rounded" />
        </div>
        <div class="mb-4">
          <button id="button" @click="submit" class="bg-white rounded">
            Submit
          </button>
        </div>
      </div-->
    </div>
  </div>
</template>

<script>
function justDate(elDate) {
  elDate.setUTCHours(0, 0, 0, 0);
  return elDate;
}

function createDate(year, month, day) {
  return justDate(new Date(year, month, day));
}

function fillInBlanks(arr) {
  let previous = null;
  return arr.map((val) => {
    if (val) {
      previous = val;
    } else {
      val = previous;
    }
    return val;
  });
}

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data: function () {
    return {
      name: "",
      weight: 0,
      weightDate: null,
      state: {
        startDate: createDate(2022, 6, 15),
        players: [
          {
            name: "Eric",
            color: "#FFA500",
            weights: {
              "7/15": 256.4,
              "7/22": 247.8,
              "7/29": 241.8,
              "8/5" : 239.4,
              "8/12" : 237.2,
              "8/19" : 234.2
            },
          },
          {
            name: "Kelly",
            color: "#800080",
            weights: {
              "7/15": 165.8,
              "7/22": 164,
              "7/29": 162.4,
              "8/5":  162.4,
              "8/12": 162.4,
              "8/19": 161.8
            },
          },
          {
            name: "Heidi",
            color: "#e40000",
            weights: {
              "7/15": 179,	
              "7/22": 175.8,
              "7/29": 175.4,
              "8/5": 174.8,
              "8/12":174.6,
              "8/19":173.8
            },
          },
          {
            name: "Hans",
            color: "#0000e5",
            weights: {
              "7/15": 197.8,
              "7/22": 193.8,
              "7/29": 195.3,
              "8/5": 193.4,	
              "8/12":190.7,
              "8/19":187.6
            },
          },
        ],
      },
    };
  },
  computed: {
    getOptions() {
      return {
        chart: { id: "Weight Loss" },
        dataLabels: {
          formatter: function (val) {
            return val + "%";
          },
        },
        colors: this.listColors,
        xaxis: { categories: this.listCategories },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value + "%";
            },
          },
        },
      };
    },
    listCategories() {
	return Object.keys(this.state.players[0].weights);
    },
    listColors() {
      return this.state.players.map((player) => {
        return player.color;
      });
    },
    listSeries() {
      return this.state.players.map((player) => {
        const rawData = this.listCategories.map((date) => player.weights[date]);
        const firstData = rawData[0];
        return {
          name: player.name,
          color: player.color,
          data: fillInBlanks(rawData).map(
            (data) =>
              parseFloat(((data - firstData) / firstData) * 100).toFixed(2) * -1
          ),
        };
      });
    },
  },
  methods: {
    submit() {
      let player = this.state.players.find(
        (player) => player.name === this.name
      );
      if (!player) {
        alert("Name " + this.name + "does not exist");
        this.name = null;
        return;
      }
      if (this.weight < 0) {
        alert("Invalid weight" + this.weight);
        this.weight = 0;
        return;
      }
      if (!this.weightDate) {
        alert("Date must be submitted");
        return;
      }
      const arrDate = this.weightDate.split("-");
      const strDate = parseInt(arrDate[1], 10) + "/" + parseInt(arrDate[2], 10);
      player.weights[strDate] = this.weight;
      console.log(player);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
